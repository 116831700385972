import WebGLView from './webgl/WebGLView';
// import GUIView from './gui/GUIView';


export default class ParticleImage {

	constructor() {
		this.init();
	}

	init() {
		this.initWebGL();
		// this.initGUI();
		this.addListeners();
		this.animate();
		this.resize();
	}

	initWebGL() {
		this.webgl = new WebGLView(this);
		document.querySelector(".logo-container").appendChild(this.webgl.renderer.domElement);
	}

	// initGUI() {
	// 	this.gui = new GUIView(this);
	// }

	addListeners() {
		this.handlerAnimate = this.animate.bind(this);
	}

	animate() {
		this.update();
		this.draw();

		this.raf = requestAnimationFrame(this.handlerAnimate);
	}

	// ---------------------------------------------------------------------------------------------
	// PUBLIC
	// ---------------------------------------------------------------------------------------------

	update() {
		// if (this.gui.stats) this.gui.stats.begin();
		if (this.webgl) this.webgl.update();
		if (this.gui) this.gui.update();
	}

	draw() {
		if (this.webgl) this.webgl.draw();
		// if (this.gui.stats) this.gui.stats.end();
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	resize() {
		if (this.webgl) this.webgl.resize();
	}
}
