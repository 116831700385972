import { useState, useEffect } from "react";
import anime from "animejs";
import "./index.scss";

export default function LoadingPage<T>(
  WrappedComponent: React.ComponentType<T>,
  color: string
) {
  const HOC = (prop: any) => {
    const [isLoading, setIsLoading] = useState(true);

    const animate = () => {
      anime({
        targets: "#loadingScreen path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutQuart",
        duration: 2000,
        delay: function (el, i) {
          return i * 250;
        },
      });
    };

    useEffect(() => {
      animate();
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }, [setIsLoading]);

    return isLoading ? (
      <div
        style={{ background: color }}
        className="min-h-screen flex justify-center items-center"
      >
        <div id="loadingScreen">
          <div className="logo-wrapper">
            <svg id="logo" viewBox="0 0 100 100">
              <g>
                <path
                  stroke="#fff"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
                />
                <text id="H" x="34" y="65" fill="#fff" fontSize="44px">
                  H
                </text>
              </g>
            </svg>
          </div>
        </div>
      </div>
    ) : (
      <WrappedComponent {...prop} />
    );
  };

  return HOC;
}
