import { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./index.scss";
import ReactGA from "react-ga";

// components
import Loader from "components/common/loadingPage";
import Navbar from "components/common/navbar";
import ProgressBar from "components/skills/progressBar";

// constant
import { experience, skillsProgress, backgroundColors } from "constants/index";

const Skills = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <title>Skills</title>
      </Helmet>
      <div
        style={{
          background: backgroundColors.skills.bgColor,
        }}
        className="min-h-screen"
      >
        <Navbar overlayColor={backgroundColors.skills.bgColor} />
        <div className="flex flex-col lg:flex-row">
          <div className="basis-3/6">
            <div className="flex flex-col h-full mx-6 md:mx-24 pt-24 lg:mr-0">
              <h1 className="font-poppins font-medium text-4vmax text-white">
                Skills & Experience
              </h1>
              <div className="ml-4 my-6">
                {experience.map((exp, index) => {
                  return (
                    <div key={index} className="mt-5">
                      <div className="flex justify-between items-center relative">
                        <p className="skills-company font-poppins font-normal">
                          {exp.name}
                        </p>
                        <hr className="skills-line w-6/12" />
                      </div>
                      <p className="skills-location font-open-sans">
                        {exp.location}
                      </p>
                      <p className="skills-tenure">{exp.tenure}</p>
                      <p className="skills-role font-open-sans">{exp.role}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="basis-3/6 flex">
            <div className="flex flex-col w-full mx-8 my-16 md:my-24 md:mx-24 lg:items-center">
              {skillsProgress.map((skill, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col items-start mt-6 w-full"
                  >
                    <p
                      style={{ fontSize: "24px" }}
                      className="font-poppins font-normal text-white"
                    >
                      {skill.name}
                    </p>
                    <div className="w-full">
                      <ProgressBar progress={skill.progress} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader(Skills, backgroundColors.skills.bgColor);
