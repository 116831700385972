// assets
import Piotr from "assets/images/piotrProfile.jpg"
import Jason from "assets/images/jasonProfile.jpeg";
import Amir from "assets/images/amirProfile.jpeg";
import Araby from "assets/images/arabyProfile.jpeg";
import Evercare from "assets/work/evercare.jpg";
import TDC from "assets/work/TDCMockup.jpg"
import Hytribe from "assets/work/hytribeMockup.jpg"
import Primpt from "assets/work/primptMockup.jpg"
import LegalQ from "assets/work/LegalQ.jpg"
import Spookhost from "assets/work/spookhostMockup.jpg"
import UPark from "assets/work/uPark.jpg"
import Yelow from "assets/work/yelow.jpg"
import Josh from "assets/work/josh.jpg"

export const backgroundColors = {
  home: { bgColor: "linear-gradient(180deg, #1B1425 74.5%, #6B2120 146.17%)", navColor: "#1B1425" },
  about: { bgColor: "linear-gradient(180deg, #1B1425 74.5%, #6B2120 146.17%)", navColor: "#1B1425" },
  skills: { bgColor: "linear-gradient(270deg, #1B1425 29.79%, #6B2120 112.5%)", navColor: "#1B1425" },
  work: { bgColor: "linear-gradient(116.17deg, #1B1425 11.08%, #311824 49.37%, #6B2120 100.87%", navColor: "#1B1425" },
  testimonials: { bgColor: "linear-gradient(360deg, #1B1425 -0.86%, #6B2120 100%)", navColor: "#6B2120" },
  contact: { bgColor: "linear-gradient(180deg, #1B1425 74.5%, #6B2120 146.17%)", navColor: "#1B1425" }
}

export const navbarLink = [
  { name: 'Home', link: "/" },
  { name: 'About', link: "/about"},
  { name: 'Skills', link: "/skills"  },
  { name: 'Work', link: "/work" },
  { name: 'Testimonials', link: "/testimonials" },
  { name: "Contact", link: "/contact" },
  { name: "Resume", isButton: true }
]

export const skills = [
  "Puppeteer",
  'Redux',
  'AWS S3',
  'Firebase',
  'PostgreSQL',
  'Node',
  "Javascript",
  "Typescript",
  'CSS',
  "SCSS",
  "Next JS",
  "HTML",
  "MongoDB",
  "REST",
  "GraphQL",
  'React JS',
  "JEST",
  "Webpack",
  "CI/CD",
  "Git",
  "React Native",
  "Hasura",
  "RTL",
  "i18n",
  "Three js",
  "Scrum",
  "Tailwind CSS",
  "Netlify",
  "Heroku",
  "AWS Cognito",
  "React Native Reanimated",
  "Stripe"
]

export const skillsProgress = [
  { name: 'Front-end', progress: 85 },
  { name: 'Back-end', progress: 60 },
  { name: 'Testing', progress: 45 },
  { name: 'Dev-ops', progress: 20 }
]

export const experience = [
  { name: "The Drivers Cooperative", tenure: "Mar 2022 - Present", location: "New York, United States", role: "Full Stack Javascript Developer" },
  { name: "Devorbis", tenure: "Jun 2020 - Mar 2022", location: "Lahore, Pakistan", role: 'React JS - React Native Developer' },
  { name: "Freelancing", tenure: "Dec 2018 - Jun 2020", role: "Wordpress - Angular Developer" } 
]

export const testimonialsInfo = [
  {
    name: "Piotr Zientara",
    role: "CEO Xfaang",
    img: Piotr,
    desc: `As the CEO of our software house, I've had the pleasure of collaborating with Hamza Aman, a 
    standout React Native developer. Hamza's commitment to each project is exemplary, demonstrating a rare 
    work ethic in our fast-paced industry. His proficiency in React Native and React has been 
    transformative, enabling the development of efficient, scalable, and cross-platform applications. 
    What sets Hamza apart is not only his technical expertise but also his strong sense of 
    responsibility & consistently delivering high-quality work. Flexibility in adapting to new technologies 
    & availability, even beyond regular hours, has been instrumental in addressing critical 
    issues promptly.`,
  },
  {
    name: "Amir Mughal",
    role: "CTO & Cofounder Devorbis",
    img: Amir,
    desc: `Hamza is one of the most professional and trusted people I have ever worked with.
    His attitude to carefully evaluate any project and respecting agreed deadlines are some of 
    the many characteristics of his professionalism. He is also elastic and able to approach 
    projects in different languages especially (React, and ReactNative). The right (and minimum) 
    guidance joined with his passion and commitment will in very few weeks make him stand out 
    from the crowd. I highly recommend working with him and investing in his future learning 
    considering his leadership and software engineering skills.`,
  },
  {
    name: "Jason Prado",
    role: "Director of Platform The Drivers Cooperative",
    img: Jason,
    desc: `When Hamza joined our company most of the technologies were new for Hamza. 
    After the ramp up period, Hamza was productive in our codebase. Hamza has also 
    consistently been enjoyable to work with. He is curious and receptive to feedback. 
    In the months we worked together his engineering work improved significantly.`,
  },
  {
    name: "Mohammed Araby",
    role: "Client",
    img: Araby,
    desc: `Hamza was very very helpful and he finish our project with 100% what we need and 
    also help us to run the project in our machine. I'll hire again hamza to work in other 
    projects for us"`,
  },
];

export const projectsDetails = {
  evercare: {
    name: 'Evercare Caregiver',
    techStack: [
      "React Native",
      "Redux",
      "Native base alpha",
      "i18next",
      "Firebase Push notification",
      "Graphs",
      "Calender",
      "GPS Tracking",
      "Deep Linking",
      "REST API",
      "AWS Cognito"
    ],
    description: `<p>Platform for caregivers to signup for caretaking jobs.</p>
    <p>Evercare Caregiver is a Singapore based health care company providing nursing 
    care at home. Users can request a nurse and there's a dedicate <b>mobile app</b> for nurses 
    from where they can signup for posted jobs.</p>
    <br />
    <p>I was responsible for the development of mobile app. I led the development of the 
    version 1 & 2 of this app. Nurses can complete their onboarding process from the app. 
    They can apply for new jobs and optimize their search by applying different filters. Nurses can 
    keep track of their previous jobs and payments on the app. They can compare their monthly 
    progress through the graphs available in the app. The app is bilingual i.e english & chinese.
    <p />
    `,
    image: Evercare
  },
  tdc: {
    name: 'The Drivers Cooprtative',
    techStack: [
      "React JS",
      "Material UI",
      "Apollo Client",
      "Hasura",
      "Node JS",
      "PostgreSQL",
      "styled-components"
    ],
    description: `<p>Driver owned ridehailing cooperative in New York city.</p>
    <p>The Drivers Cooperative or Co-Op Ride is an American ridesharing company currently 
    operating in New York city is a workers cooperative, owned collectively by the drivers.</p>
    <br />
    <p>I worked on the dispatch web portal of The Drivers Cooperatives(TDC). Dispatch portal 
    is used by TDC internally to manage the drivers and daily rides. Drivers onboarding process, 
    rides tracking, rides management and real time tracking are all done on this dispatch portal.</p>
    `,
    image: TDC
  },
  hytribe: {
    name: "Hytribe",
    techStack: [
      "React Native",
      "Redux",
      "UI Kitten",
      "Camera",
      "Image Picker",
      "Maps",
      "Calenders",
      "REST API",
      "Firbase Admob",
      "Firebase Auth",
      "Social Login",
      "Google Signin"
    ],
    description: `<p>Proper Hygiene. Everywhere</p>
    <p>The application was created amidst the COVID-19 pandemic, providing an avenue for shops, cafes, 
    restaurants, and other establishments to register and update their profiles with the SOPs they adhere 
    to. <p/>
    <p>Users have the capability to discover and view all registered businesses in their vicinity along 
    with the specific SOPs implemented by each establishment.</p>
    </p>
    `,
    image: Hytribe,
  },
  primpt: {
    name: "Renae Primpt",
    techStack: [
      "React Native",
      "Redux",
      "UI Kitten",
      "Calenders",
      "Camera",
      "Image Picker",
      "Geocoding",
    ],
    description: `<p>On-demand and in-home beauty services.</p>
    <p>The app was developed during COVID-19 to provide on-demand beauty serives at home. 
    Like uber for hairstylists and cosmetologists.
    </p>
    <br />
    <p>Unfortunatley this product was never completed as client's investor backed down as the 
    COVID-19 restrictions started to ease down.</p>
    `,
    image: Primpt,
  },
  legalQ: {
    name: "LegalQ",
    techStack: [
      "React JS",
      "styled components",
      "Node JS",
      "Express",
      "Mongodb",
      "Mongoose",
      "GraphQL",
      "JWT",
      "cookies",
    ],
    description: `<p>Get expert help from attorneys now.</p>
    <p>Legal advice feels complex, time-consuming, and overwhelming. The LegalQ enables 
    customers with legal needs a consultation with a licensed attorney in their jurisdiction.</p>
    <br />
    <p> I had the opportunity to actively engage in both front-end and back-end development for the 
    project. My primary role on the front-end involved implementing substantial code optimization measures 
    to enhance overall performance. I was also responsible for designing the backend architecture and 
    crafting the database schema for the product.</p>
    `,
    image: LegalQ,
  },
  spookhost: {
    name: "Spookhost",
    techStack: [
      "React Native",
      "Redux",
      "Webview",
      "Mapbox",
      "Image Picker",
      "Push Notifications",
      "Messaging",
      "Geolocation",
    ],
    description: `<p>Spookhost a social entertainment application.</p>
    <p>Users can post stories, do live chat with other followed users. They can also view 
    live streaming of Movies. Spookhost provides a Map feature in which users can find exact 
    location, details and feedback of movies.</p>`,
    image: Spookhost,
  },
  uPark: {
    name: 'uPark',
    techStack: [
      "React Native Expo",
      "Redux Toolkit",
      "React Native Reanimated",
      "i18next",
      "Firebase Push notification",
      "Redash",
      "Stripe",
      "Geolocation",
      "Google Places",
      "REST API",
    ],
    description: `<p>A mobile parking app designed for regions with a high density of cars, 
    aiming to enhance visibility and ensure parking availability throughout Europe.</p>
    <br />
    <p>I took charge of the creation of the 2nd version of a mobile app. Users have the ability to 
    search for available parking spaces that are equipped with IoT technology to provide real-time 
    occupancy updates. Through the use of a spinner, users can customize their parking duration, and the 
    fare is instantly calculated based on their selected time.
    <p />
    `,
    image: UPark
  },
  yelow: {
    name: 'Yelow',
    techStack: [
      "React Native",
      "Redux Toolkit",
      "React Native Reanimated",
      "i18next",
      "Firebase Push notification",
      "Stripe",
      "Geolocation",
      "REST API",
      "Drag & Drop",
      "Vision Camera"
    ],
    description: `<p>An app for sharing what and where you like to eat, discover an innovative way of 
    organizing meetings - with friends and business, try new flavors and places.</p>
    <br />
    <p>
    I served as the lead engineer for this application and played a crucial role in designing the app's 
    architecture, along with development. Yelow enables users to tailor restaurant, cafe, and bar 
    offerings based on their dietary preferences, desired company, and facilitates easy event planning. 
    By merging aspects of social media with the convenience of restaurant booking and ordering, 
    Yelow presents a distinctive approach to selecting dining options, visiting restaurants, and sharing 
    culinary recommendations. 
    <p />
    `,
    image: Yelow
  },
  josh: {
    name: 'Josh',
    techStack: [
      "Next JS",
      "Typescript",
      "Redux Toolkit",
      "Algolia",
      "i18next",
      "Material UI",
      "Drag & Drag",
      "Cookies",
    ],
    description: `<p>A wedding marketplace designed to link couples with vendors offering a range of 
    services related to weddings.</p>
    <br />
    <p>
    Being lead engineer for this application, I played a pivotal role in both architecting the app and 
    contributing to its development. Josh provides an advanced search feature with over 50 different 
    filters to precisely refine results based on specific needs.The platform offers dedicated dashboards 
    for vendors and couples, enabling them to manage their offerings and wedding planning, respectively. 
    It serves as a comprehensive A-Z solution for both vendors and couples.
    <p />
    `,
    image: Josh
  },
}