import { useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import "./index.scss";

// components
import Loader from "components/common/loadingPage";
import Navbar from "components/common/navbar";

// scripts
import ParticleImage from "./scripts/";

// constants
import { backgroundColors } from "constants/index";

const Home = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    new ParticleImage();
  }, []);

  return (
    <>
      <Helmet>
        <title>Hamza Aman</title>
      </Helmet>
      <div
        style={{
          background: "linear-gradient(180deg, #1B1425 74.5%, #6B2120 146.17%)",
        }}
      >
        <Navbar overlayColor={backgroundColors.home.bgColor} />
        <div className="info">
          <h1
            style={{ fontSize: "3.5vmax" }}
            className="font-poppins font-medium text-white"
          >
            Hamza Aman
          </h1>
          <h2
            style={{ fontSize: "1.4vmax" }}
            className="font-open-sans font-extralight text-white"
          >
            Full Stack Javascript Engineer
          </h2>
        </div>
        <div className={"logo-container"}></div>
      </div>
    </>
  );
};

export default Loader(Home, backgroundColors.home.bgColor);
