import { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import "./index.scss";

// components
import Loader from "components/common/loadingPage";
import Navbar from "components/common/navbar";

// constants
import { testimonialsInfo, backgroundColors } from "constants/index";

const Testimonials = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <title>Testimonials</title>
      </Helmet>
      <div
        style={{
          background: backgroundColors.testimonials.bgColor,
        }}
        className="min-h-screen"
      >
        <Navbar overlayColor={backgroundColors.testimonials.bgColor} />
        <div className="flex flex-col items-center justify-center min-h-screen-9/10">
          <p className="testimonials-txt1 text-5vmax text-white">”</p>
          <div className="flex flex-col justify-center w-screen">
            <Carousel
              autoPlay
              infiniteLoop
              showArrows={false}
              showStatus={false}
              transitionTime={2}
            >
              {testimonialsInfo.map((testimonial, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="flex flex-col justify-start items-center flex-wrap"
                    >
                      <div>
                        <img
                          className="profileImg"
                          alt={testimonial.name}
                          src={testimonial.img}
                        ></img>
                      </div>
                      <p
                        style={{ fontSize: "32px" }}
                        className="font-poppins font-medium text-white mt-4 "
                      >
                        {testimonial.name}
                      </p>
                      <h4 className="font-poppins text-5vw md:text-3vw lg:text-2vw font-medium text-white">
                        {testimonial.role}
                      </h4>
                      <p className="font-open-sans text-white mt-7 w-9/12 lg:w-8/12 xl:w-6/12">
                        {testimonial.desc}
                      </p>
                    </div>
                    <div style={{ height: "8vh" }}></div>
                  </>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader(Testimonials, backgroundColors.testimonials.bgColor);
