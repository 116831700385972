import { BrowserRouter, Route, Routes } from "react-router-dom";

// componensts
import Home from "features/home";
import About from "features/about";
import Skills from "features/skills";
import Work from "features/work";
import Testimonials from "features/testimonials";
import Contact from "features/contact";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/work" element={<Work />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
