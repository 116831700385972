import Modal from "react-modal";
import "./index.scss";

// constants
import { projectsDetails } from "constants/index";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  projectName: string;
};

type ProjectKey = keyof typeof projectsDetails;

const ModalComponent = ({ isOpen, onClose, projectName }: Props) => {
  const project = projectName as ProjectKey;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="overlay"
      className="content"
    >
      <div className="modal-content-container">
        <img
          className="hidden md:block md:object-cover lg:w-full"
          style={{
            height: 400,
          }}
          alt={projectsDetails[project].name}
          src={projectsDetails[project].image}
        />

        <div className="details">
          <div className="flex justify-center">
            <h2 className="font-poppins font-medium text-white text-2.5vmax lg:text-1.6vmax">
              {projectsDetails[project].name}
            </h2>
          </div>
          <div className="mt-8 px-12">
            <div
              className="font-open-sans text-white"
              dangerouslySetInnerHTML={{
                __html: projectsDetails[project].description,
              }}
            />
            <div className="flex flex-row flex-wrap mt-6">
              {projectsDetails[project].techStack.map((tech, index) => (
                <div
                  key={index}
                  style={{ backgroundColor: "#000", opacity: 0.3 }}
                  className="mr-2 mt-2 rounded-md"
                >
                  <p className="font-open-sans text-white px-2 py-1">{tech}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
