import "./progressBar.scss";
import ProgressBar from "@ramonak/react-progress-bar";

type Props = {
  progress: number;
};

const ProgressBarComponent = ({ progress }: Props) => {
  return (
    <ProgressBar
      className="progress-bar"
      bgColor="#6B2120"
      baseBgColor="#322b3b"
      height="5px"
      borderRadius="0"
      isLabelVisible={false}
      transitionDuration="1.5s"
      animateOnRender={true}
      completed={progress}
    />
  );
};

export default ProgressBarComponent;
