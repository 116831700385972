import fragShader from "./fragment.glsl"
import vertShader from "./vertex.glsl"
const glsl = require('glslify');

export const shaders = {
    particle: {
        frag: glsl(fragShader),
        vert: glsl(vertShader)
    }
};
