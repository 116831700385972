import Navigation from "navigation";
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || "");

function App() {
  return <Navigation />;
}

export default App;
