import { useState } from "react";
import "./index.scss";
import { useNavigate, useLocation } from "react-router-dom";

// component
import OutlinedButton from "components/common/outlineButton";

// assets
import Logo from "assets/images/logoOutline.svg";
import Resume from "assets/files/resume.pdf";

// constant
import { navbarLink } from "constants/index";

const Navbar = ({
  overlayColor,
  align = "items-end",
}: {
  overlayColor: string;
  align?: string;
}) => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={`flex ${align} h-v-1/10`}>
      <div
        style={{ width: toggle ? "100%" : "0%", background: overlayColor }}
        className="sidenav"
      >
        <div
          style={{ fontSize: "38px" }}
          className="absolute top-4 right-8 cursor-pointer text-white"
          onClick={() => setToggle(false)}
        >
          &times;
        </div>
        <div
          style={{ marginTop: "-10vh" }}
          className="flex flex-col justify-center items-center h-full"
        >
          {navbarLink.map((nav, index) =>
            nav.isButton ? (
              <OutlinedButton
                className="mt-6"
                key={index}
                title={nav.name}
                type="button"
                version="md-round"
                onClick={() => window.open(Resume)}
              />
            ) : (
              <p
                key={index}
                className="nav-links mt-6 font-open-sans text-3vmax lg:text-2vmax xl:text-vmax"
                style={{ color: location.pathname === nav.link ? "#fff" : "" }}
                onClick={() => navigate(`${nav.link}`)}
              >
                {nav.name}
              </p>
            )
          )}
        </div>
      </div>
      <div style={{ zIndex: 1 }} className="absolute inset-x-0">
        <div className="flex justify-between items-center px-5 md:px-10">
          <img
            alt="Logo"
            className="outline-logo cursor-pointer"
            src={Logo}
            onClick={() => navigate("/")}
          />

          <div className="nav-icon" onClick={() => setToggle(true)}>
            <div className="icon-line md:h-1" />
            <div className="icon-line md:h-1" />
            <div className="icon-line md:h-1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
