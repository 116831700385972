import "./index.scss";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string;
  version?: string;
  onClick?: () => void;
  type: string;
  disabled?: boolean;
  className?: string;
};

const OutlinedButton = ({
  title,
  onClick,
  version = "md",
  type,
  disabled = false,
  className = "",
}: Props) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={`${version} ${className}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default OutlinedButton;
