import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import "./index.scss";

// components
import Loader from "components/common/loadingPage";
import Navbar from "components/common/navbar";
import Modal from "components/work/modal";

// assets
import EvercareImg from "assets/work/evercare.jpg";
import TDCImg from "assets/work/TDC.jpg";
import HytribeImg from "assets/work/hytribe.jpg";
import LegalQImg from "assets/work/LegalQ.jpg";
import PrimptImg from "assets/work/primpt.jpg";
import SpookhostImg from "assets/work/spookhost.jpg";
import JoshImg from "assets/work/josh.jpg";
import UPark from "assets/work/uPark.jpg";
import Yelow from "assets/work/yelow.jpg";

// assets
import { backgroundColors } from "constants/index";

const Work = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const projects = [
    {
      name: "evercare",
      img: EvercareImg,
      title: "Evercare Caregiver",
    },
    {
      name: "tdc",
      img: TDCImg,
      title: "The Drivers Cooperative",
    },
    {
      name: "legalQ",
      img: LegalQImg,
      title: "LegalQ",
    },
    {
      name: "spookhost",
      img: SpookhostImg,
      title: "Spookhost",
    },
    {
      name: "josh",
      img: JoshImg,
      title: "Josh",
    },
    {
      name: "primpt",
      img: PrimptImg,
      title: "Renae Primpt",
    },
    {
      name: "yelow",
      img: Yelow,
      title: "Yelow",
    },

    {
      name: "uPark",
      img: UPark,
      title: "uPark",
    },
    {
      name: "hytribe",
      img: HytribeImg,
      title: "Hytribe",
    },
  ];

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <title>Work</title>
      </Helmet>
      <div
        style={{
          background: backgroundColors.work.bgColor,
        }}
        className="min-h-screen"
      >
        <Navbar
          overlayColor={backgroundColors.work.bgColor}
          align="items-center"
        />
        <div className="grid md:grid-cols-2 md:grid-rows-2 lg:grid-cols-3 lg:grid-rows-3 gap-0 lg:h-v-9/10">
          {projects.map((project) => (
            <div
              className="md:col-span-1 md:row-span-1 img-container"
              style={{ flexGrow: 1 }}
              onClick={() => {
                setIsModalOpen(true);
                setProjectName(project.name);
              }}
            >
              <img
                className="img fit-img"
                alt={project.title}
                title={project.title}
                src={project.img}
              ></img>
              <p className="project-name">{project.title}</p>
            </div>
          ))}
        </div>
        {isModalOpen && (
          <Modal
            isOpen
            onClose={() => setIsModalOpen(false)}
            projectName={projectName}
          />
        )}
      </div>
    </>
  );
};

export default Loader(Work, backgroundColors.work.bgColor);
