import { useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

// components
import Loader from "components/common/loadingPage";
import Navbar from "components/common/navbar";

// constants
import { skills, backgroundColors } from "constants/index";

const About = () => {
  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // eslint-disable-next-line
    eval(
      `try {
         TagCanvas.Start(
           'myCanvas',
           'tags',
           {
            textColour: '#fff',
            outlineColour: '#0000',
            shape: 'circle',
            pinchZoom: true,
            wheelZoom: false,
            activeCursor: 'default',
            noSelect: true
          }
         );
       }
       catch(e) {
         document.getElementById('myCanvasContainer').style.display = 'none';
       }`
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>About</title>
      </Helmet>
      <div
        style={{
          background: backgroundColors.about.bgColor,
        }}
        className="min-h-screen"
      >
        <Navbar overlayColor={backgroundColors.about.bgColor} />
        <div className="flex flex-col lg:flex-row ">
          <div className="px-16 flex-1 flex flex-col mt-24 lg:px-8 lg:ml-8">
            <h3 className="hidden font-poppins font-medium text-white text-5vmax lg:text-4vmax md:block">
              Lets know me
            </h3>
            <h3 className="font-poppins font-medium md:font-normal text-white text-5vmax md:text-4vmax">
              Who am I?
            </h3>
            <span className="my-9 font-open-sans text-base text-white lg:pr-16">
              <p>
                I'm a Full Stack developer with a focus on Front-End
                technologies.
              </p>
              <p>
                With a strong foundation in web and mobile app development
                spanning over 5 years, I have navigated diverse roles as a
                freelancer, collaborated with startups, and served as a lead
                engineer in a services company.
              </p>
              <br />
              <p>I hold a bachelor's degree in computer science.</p>
              <p>
                Equipped with just the right tools, I excel in both team
                environments and possess the capability to work autonomously. My
                proficiency extends to delivering prompt, robust solutions
                customized for scalability, performance, and efficiency.
              </p>
            </span>
          </div>
          <div className="flex-1">
            <div
              className="flex justify-center items-center"
              id="myCanvasContainer"
            >
              <canvas
                width={width > 900 ? width / 2.5 : width / 1.4}
                height={width > 900 ? height * 0.9 : height / 1.5}
                id="myCanvas"
              >
                <div id="tags">
                  <ul>
                    {skills.map((skill, index) => {
                      return (
                        <li key={index}>
                          <a href="/about">{skill}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader(About, backgroundColors.about.bgColor);
